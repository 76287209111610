.cf_flex-baseline {
  display: flex;
  align-items: baseline;
  &-between {
    @extend .cf_flex-baseline;
    justify-content: space-between;
  }
  &-center {
    @extend .cf_flex-baseline;
    justify-content: center;
  }
  &-normal {
    @extend .cf_flex-baseline;
    justify-content: normal;
  }
}
.cf_flex-center {
  display: flex;
  align-items: center;
  &-around {
    @extend .cf_flex-center;
    justify-content: space-around;
  }
  &-between {
    @extend .cf_flex-center;
    justify-content: space-between;
  }
  &-center {
    @extend .cf_flex-center;
    justify-content: center;
  }
  &-end {
    @extend .cf_flex-center;
    justify-content: flex-end;
  }
  &-normal {
    @extend .cf_flex-center;
    justify-content: normal;
  }
  &-start {
    @extend .cf_flex-center;
    justify-content: flex-start;
  }
}
.cf_flex-normal {
  display: flex;
  align-items: normal;
  &-around {
    @extend .cf_flex-normal;
    justify-content: space-around;
  }
  &-between {
    @extend .cf_flex-normal;
    justify-content: space-between;
  }
  &-center {
    @extend .cf_flex-normal;
    justify-content: center;
  }
  &-end {
    @extend .cf_flex-normal;
    justify-content: flex-end;
  }
  &-normal {
    @extend .cf_flex-normal;
    justify-content: normal;
  }
  &-start {
    @extend .cf_flex-normal;
    justify-content: flex-start;
  }
}
.cf_flex-start {
  display: flex;
  align-items: flex-start;
  &-center {
    @extend .cf_flex-start;
    justify-content: center;
  }
  &-start {
    @extend .cf_flex-start;
    justify-content: start;
  }
}
.cf_flex-end {
  display: flex;
  align-items: flex-end;
  &-center {
    @extend .cf_flex-end;
    justify-content: center;
  }
  &-normal {
    @extend .cf_flex-end;
    justify-content: normal;
  }
  &-between {
    @extend .cf_flex-end;
    justify-content: space-between;
  }
  &-start {
    @extend .cf_flex-end;
    justify-content: start;
  }
}
.cf_flex-stretch {
  display: flex;
  align-items: stretch;
  &-start {
    @extend .cf_flex-stretch;
    justify-content: flex-start;
  }
}
