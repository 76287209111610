/* You can add global styles to this file, and also import other style files */
@import './app/_style/site.scss';
@import './app/_style/molecules/loading-screen.scss';
@import '~ngx-toastr/toastr.css';

html {
  /* It is important NOT to set height: 100%;, otherwise that undermines the iframe-resizer*/
  height: auto;
}


:root {
  --tag-color: #{$neutral-08};
  --main-font-family: JohnsonText, Arial, Lucida Grande, sans-serif;
  --map-marker-font-family: var(--main-font-family);
  --tooltip-popup-font-family: var(--main-font-family);
  --color1: rgb(232, 230, 227);
  --color2: rgb(193, 187, 179);
  --color3: rgb(86, 76, 71);
  --color4: rgb(0, 0, 0);
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }
}

/*
 * This pattern is called Style Linking. This allows us to define a single font-family attribute
 * on all of our text, and different font-weights will resolve to the correct font style. See
 * https://www.smashingmagazine.com/2013/02/setting-weights-and-styles-at-font-face-declaration/.
 * Note that this is not supported in very old browsers (such as IE 9, Blackberry, iPad 1) but that
 * shouldn't be an issue here.
 * 
 * Note: these just define the named font-family so it can be specified elsewhere- it does not
 * specify using this font anywhere, just makes a non-standard font available for use.
 */
@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-Light.otf');
  font-weight: 300; // lighter than font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-LightItalic.otf');
  font-weight: 300; // lighter than font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-Regular.otf');
  font-weight: 400; // equivalent to font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-RegularItalic.otf');
  font-weight: 400; // equivalent to font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-Medium.otf');
  font-weight: 500; // slightly bolder than font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-MediumItalic.otf');
  font-weight: 500; // slightly bolder than font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-Bold.otf');
  font-weight: 700; // equivalent to font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-Bold.otf');
  font-weight: 900; // bolder than bold. Used primarily for large header text
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonDisplay';
  src: url('./assets/fonts/JohnsonDisplay/JohnsonDisplay-BoldItalic.otf');
  font-weight: 900; // bolder than bold. Used primarily for large header text
  font-style: italic;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-Light.otf');
  font-weight: 300; // lighter than font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-LightItalic.otf');
  font-weight: 300; // lighter than font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-Regular.otf');
  font-weight: 400; // equivalent to font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-RegularItalic.otf');
  font-weight: 400; // equivalent to font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-Medium.otf');
  font-weight: 500; // slightly bolder than font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-MediumItalic.otf');
  font-weight: 500; // slightly bolder than font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-Bold.otf');
  font-weight: 700; // equivalent to font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-Bold.otf');
  font-weight: 900; // bolder than bold. Used primarily for large header text
  font-style: normal;
}

@font-face {
  font-family: 'JohnsonText';
  src: url('./assets/fonts/JohnsonText/JohnsonText-BoldItalic.otf');
  font-weight: 900; // bolder than bold. Used primarily for large header text
  font-style: italic;
}

body {
  font-family: var(--main-font-family);
  font-size: $font-size-small;
  color: $black;
  background-color: $default-background-color;
  height: auto;
  margin: 0;
  padding: 10px;
  /* It is important NOT to set height: 100%;, otherwise that undermines the iframe-resizer*/
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-family: var(--main-font-family);
  font-size: $font-size-large;
  line-height: 30px;
  font-weight: 800;
  color: $neutral-08;
  letter-spacing: -0.38px;
  margin: 0;
}

h2 {
  @extend .h2-headline-lg;
  color: $black;
}

h3 {
  font-family: var(--main-font-family);
  font-size: $font-size-normal;
  line-height: 20px;
  font-weight: bold;
  color: $neutral-08;
  margin: 0;
}

h4 {
  font-family: var(--main-font-family);
  font-size: $font-size-small;
  line-height: 19px;
  font-weight: bold;
  color: $neutral-08;
  margin: 0;
}

h5 {
  font-family: var(--main-font-family);
  font-size: $font-size-small;
  line-height: 18px;
  font-weight: bold;
  color: $neutral-08;
  margin: 0;
}

p {
  font-family: var(--main-font-family);
  font-size: $font-size-small;
  line-height: 17px;
  font-weight: normal;
  color: $neutral-07;
  margin: 0;
}

textarea {
  font-family: var(--main-font-family);
  font-size: $font-size-small;
  line-height: 18px;
  font-weight: normal;
  color: $black;
}

button:focus {
  outline: none !important;
}

/* Override default ngx-toastr styles in order to properly position within iframe (ADJQ-283)
 */
.toast-container {
  position: static;

  &.toast-center-center {
    top: initial;
    left: initial;
    transform: initial;
  }
}
