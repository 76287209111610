// Brand colors, see https://brandcenter.jnj.com/document/416#/visual-identity/color/color-values
$primary-brand: rgb(235, 23, 0);

$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);

$accent-01: rgb(251, 224, 88);
$accent-02: rgb(255, 96, 23);
$accent-03: rgb(158, 0, 0);
$accent-04: rgb(177, 155, 235);
$accent-05: rgb(140, 59, 187);
$accent-06: rgb(84, 25, 128);
$accent-07: rgb(105, 208, 255);
$accent-08: rgb(15, 104, 178);
$accent-09: rgb(0, 70, 133);
$accent-10: rgb(83, 206, 118);
$accent-11: rgb(50, 135, 20);
$accent-12: rgb(59, 90, 13);

$neutral-01: rgb(249, 248, 247);
$neutral-02: rgb(241, 239, 237);
$neutral-03: rgb(232, 230, 227);
$neutral-04: rgb(213, 207, 201);
$neutral-05: rgb(193, 187, 179);
$neutral-06: rgb(163, 153, 146);
$neutral-07: rgb(129, 118, 111);
$neutral-08: rgb(86, 76, 71);

// Opacity
$white-eighty-five-percent: (
    $white,
    0.85
);

$black-zero-percent: rgba($black, 0);
$black-one-half-percent: rgba($black, 0.05);
$black-ten-percent: rgba($black, 0.1);
$black-twenty-percent: rgba($black, 0.2);
$black-thirty-percent: rgba($black, 0.3);
$black-forty-percent: rgba($black, 0.4);
$black-sixty-percent: rgba($black, 0.16);
$black-eighty-percent: rgba($black, 0.8);

$netural-08-ten-percent: rgba($neutral-08, 0.1);
$neutral-08-twenty-percent: rgba($neutral-08, 0.2);
$neutral-08-thirty-percent: rgba($neutral-08, 0.3);
$neutral-08-fifty-percent: rgba($neutral-08, 0.5);
$neutral-08-eighty-percent: rgba($neutral-08, 0.8);

// Configurable subsets
$error-color: $accent-03;
$required-field-color: $accent-02;
$tag-color: --tag-color;
$default-background-color: $white;

$approved-background-color: $netural-08-ten-percent;
$declined-background-color: $neutral-08-eighty-percent;
$button-border: 1px solid $neutral-08-thirty-percent;
$button-border-top: 1px solid $netural-08-ten-percent;
$box-shadow: 0 4px 6px -1px $black-ten-percent, 0 2px 4px -1px $black-sixty-percent;

// font sizes
$font-size-x-small: 10px;
$font-size-small: 14px;
$font-size-normal: 18px;
$font-size-large: 24px;
$font-size-x-large: 30px;
$font-size-xx-large: 48px;
$font-size-xxl: 60px;

// Unused, but the sass-loader fails if it is not defined.
// We shouldn't use this, but leaving it in is simpler than
// debugging our libraries
$font-size-78px: 78px;


$site-header-height: 124px;
$app-min-height: 850px;
$max-app-width: 1440px;
$min-app-width: 300px;
$default-app-width: 100%;
$default-filter-width: 90vw;
$default-notification-width: 952px;
$default-line-stroke-width: 1px;
$default-search-area-width: 355px;
$default-update-content-width: 1440px;
$small-border-radius: 2px;
$standard-border-radius: 4px;
$large-border-radius: 8px;
$x-large-border-radius: 10px;
$large-button-border-radius: 25px;
$zindex-tooltip: 5;
$zindex-progress-indicator: 100000;
$zindex-progress-indicator-content: $zindex-progress-indicator + 1;
$my-interests-width: 960px;
$review-button-height: 100px;
$company-logo-size: 120px;
$company-logo-size-large: 100px;
$company-logo-size-medium: 80px;
$company-logo-size-small-plus: 56px;
$company-logo-size-small: 50px;