$checkbox-radio-size: 20px;
$radio-dot-size: 11px;
$background-image-size: calc(#{$checkbox-radio-size} - 2px); // must be smaller to account for 1px border

input:focus,
textarea:focus,
select:focus {
    outline: none !important;
}

input[type=number] {
    /* Firefox */
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

input[type=checkbox].checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: $checkbox-radio-size;
    margin: 0 5px 0 0;
    position: relative;
    vertical-align: sub;
    width: $checkbox-radio-size;
}

.checkbox:after {
    background-color: $white;
    border: 1px solid $neutral-07;
    border-radius: $small-border-radius;
    content: "";
    cursor: pointer;
    height: $checkbox-radio-size;
    left: 0;
    position: absolute;
    width: $checkbox-radio-size;
}

.checkbox:checked:after {
    background-color: $white;
    border: $small-border-radius solid $primary-brand;
    border-radius: $small-border-radius;
    content: "";
    cursor: pointer;
    height: $checkbox-radio-size;
    left: 0;
    position: absolute;
    width: $checkbox-radio-size;
}

.checkbox:checked:before {
    @include iconAsBackgroundImage(url('/assets/common/Line55.svg'), $background-image-size, $background-image-size, contain);
    content: "";
    position: absolute;
    bottom: 0px;
    left: 2px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 1;
}

.checkbox.indeterminate {
    &:after {
        background-color: $neutral-07;
        border: 1px solid $neutral-07;
    }

    &:before {
        content: '';
        position: absolute;
        background: none;
        border-top: 2px solid $white;
        left: 5px;
        top: 8px;
        width: 9px;
        z-index: 1;
    }
}

// Custom radio button styling informed from here
// https://moderncss.dev/pure-css-custom-styled-radio-buttons/
input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $neutral-07;
    border-radius: 50px;
    box-shadow: none;
    cursor: pointer;
    display: grid;
    height: $checkbox-radio-size + 2px; // Add the border, so we don't have to mess around with the radio button's box-sizing.
    place-content: center;
    width: $checkbox-radio-size + 2px;
}

input[type=radio]:checked {
    border: 1px solid $accent-06;
}

input[type=radio]::before {
    background-color: $primary-brand;
    border-radius: 50px;
    content: "";
    height: $radio-dot-size;
    transform: scale(0);
    width: $radio-dot-size;
}

input[type=radio]:checked::before {
    transform: scale(1);
}