
.h2-headline-lg {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 300;
    font-size: $font-size-xx-large;
    line-height: 64px;
    /* identical to box height, or 133% */
    letter-spacing: -2px;
    color: $primary-brand;
}

.subhead-lg {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 300;
    font-size: $font-size-x-large;
    line-height: 48px;
    /* identical to box height, or 133% */
    letter-spacing: -1.25px;
    color: $neutral-08;
}
.body-copy-header-lg {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-large;
    line-height: 32px;
    /* identical to box height, or 133% */
    letter-spacing: -0.75px;
    color: $black;
}

.body-copy-lg {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 300;
    font-size: $font-size-large;
    line-height: 32px;
    /* identical to box height, or 133% */
    letter-spacing: -0.5px;
    color: $neutral-08;
}

.caption-lg {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 300;
    font-size: $font-size-normal;
    line-height: 24px;
    /* identical to box height, or 141% */
    color: $neutral-08;
}

.eyebrow-lg {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-normal;
    line-height: 23px;
    /* identical to box height */
    color: $primary-brand;
}

.cta-label {
    @extend .cf_flex-center-center;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-normal;
    line-height: 20px;
}
