@import './variables.scss';

@mixin box-caret($top-before, $right-before: null, $top-after: null, $right-after: null, $left-before: null, $left-after: null, $backgroundColor: $white) {
    &:before {
        @include position(absolute, $top: $top-before, $right: $right-before, $left: $left-before);
        display: inline-block;
        border-right: 5px solid transparent;
        border-top: 12px solid $backgroundColor;
        border-left: 5px solid transparent;
        border-top-color: $backgroundColor;
        content: '';
    }

    &:after {
        @include position(absolute, $top: $top-after, $right: $right-after, $left: $left-after);
        display: inline-block;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $backgroundColor;
        border-left: 6px solid transparent;
        content: '';
    }
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}

@mixin dot($color, $radius) {
    background-color: $color;
    border-radius: $radius;
    height: $radius * 2;
    width: $radius * 2;
}

@mixin dropdown-caret($image, $height, $width: $height, $cover: cover, $top: null, $right: null, $bottom: null, $left: null) {
    &:before {
        @include iconAsBackgroundImage($image, $height, $width, $cover);
        @include position(absolute, $top: $top, $right: $right, $bottom: $bottom, $left: $left);
        content: '';
    }
}

@mixin iconAsBackgroundImage($image, $height, $width: $height, $cover: cover) {
    background-image: $image;
    background-size: $cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: $height;
    width: $width;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin right-arrow($image, $height, $width: $height, $cover: auto, $marginLeft) {
    margin-left: $marginLeft;
    @include iconAsBackgroundImage($image, $height, $width, $cover);
}

@mixin right-caret($top, $right: null, $left: null, $size: 5px, $color: $white) {
    &:before {
        @include position(absolute, 19px, null, 0);
        display: inline-block;
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        border-left-color: $color;
        content: '';
    }
}

@mixin tooltip($width, $top-before, $left-before, $top-after, $left-after, $backgroundColor: $black) {
    position: absolute;
    width: $width;
    background: $backgroundColor;
    padding: 15px 15px 10px;
    z-index: $zindex-tooltip;
    @include box-caret($top-before, null, $top-after, null, $left-before, $left-after, $backgroundColor);
}

// right-before is at the end because it is a later addition and we need to maintain backward compatibility (or rework every call)
@mixin tooltipPositionable($width, $top-before, $left-before, $top-after, $left-after, $right-before, $backgroundColor: $black, ) {
    position: absolute;
    width: $width;
    background: $backgroundColor;
    padding: 15px 15px 10px;
    z-index: $zindex-tooltip;
    @include box-caret($top-before, $right-before, $top-after, null, $left-before, $left-after, $backgroundColor);
}

@mixin scrollable($height, $max-height) {
    height: $height;
    max-height: $max-height;
    overflow: auto; // Supported by all browsers, but cuts into container width
    overflow: overlay; // Only supported by webkit
    -ms-overflow-style: -ms-autohiding-scrollbar; // Supported by IE/Edge

    &::-webkit-scrollbar {
        width: 8px;
        height: 200px;
    }

    &::-webkit-scrollbar-track {
        /* Track */
        -webkit-box-shadow: inset 0 0 6px $black-zero-percent;
        -webkit-border-radius: $standard-border-radius;
        background-color: $neutral-02;
        border-radius: $standard-border-radius;
    }

    &::-webkit-scrollbar-thumb {
        /* Handle */
        -webkit-border-radius: $standard-border-radius;
        border-radius: $standard-border-radius;
        background-color: $neutral-03;
        // Make the handle look like it's not touching the edge
        border: 2px solid transparent;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background-color: transparent;
    }
}

@mixin buttonIcon($iconPath, $height, $width: $height) {
    @include iconAsBackgroundImage($iconPath, $height, $width);
    display: block;
    content: '';
    margin-right: 5px;
}

// Hide an element from view, but keep it in the DOM
@mixin visuallyHidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    /* added line */
}

@mixin dropdown-menu-option {
    @extend .cf_flex-normal-start;
    border-radius: $standard-border-radius;
    flex-direction: row;
    padding: 8px 4px 8px 9px;

    &:hover {
        background-color: $neutral-02;
    }

    label {
        color: $black;
        cursor: pointer;
        font-weight: 300;
        margin-left: 4px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 3px;

        &.selected {
            font-weight: 400;
        }
    }
}