swiper-slide {
  height: 100%;
  width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
  overflow: clip;
}

// This is a lightweight element to be used on touch devices in lieu of the
// simple system "title" tooltips.  We could possibly convert it to use the
// tooltip() mixin, but currently it's just centered on the parent element
// since we didn't want to deal with positioning it over the svg sub-element
// and worrying about clipping near edges of a small screen.
.touchTooltip {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  padding: 2px;
}

// If an element is tagged witih the touchTooltip directive, a long touch on
// a touch device brings up the tooltip.  This style will be placed on the
// element, in hopes that it will suppress the default context menu.
// Note: do not set touch-action: none; that will prevent touch scrolling (ADJQ-1569)
  .touchTooltipAnchor {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -webkit-touch-callout: none;
}