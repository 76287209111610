.loading_overlay {
    background-color: $white-eighty-five-percent;
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: $zindex-progress-indicator;
}
.lower {
    left: 50vw;
    position: fixed;
    top: 25vh;
    transform: translate(-50%, -50%);
}
.progress-wrapper {
    padding-top: 15px;
    text-align: center;
}
.progress-animation {
    z-index: $zindex-progress-indicator-content;
}
.progress-animation-span {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}
.progress-animation-span:nth-child(1) {
    background: var(--color1);
}
.progress-animation-span:nth-child(2) {
    background: var(--color2);
}
.progress-animation-span:nth-child(3) {
    background: var(--color3);
}
.progress-animation-span:nth-child(4) {
    background: var(--color4);
}
.loading_text {
    color: $neutral-08;
    font-size: $font-size-normal;
    font-weight: bold;
    z-index: $zindex-progress-indicator-content;
}
